import React, { useEffect } from "react";
import TopSecDashboard from './dashboardComponents/TopSecDashboard';
import MidSecDashboard from './dashboardComponents/MidSecDashboar';
import HowItWorksSecDashboard from './dashboardComponents/HowItSecDashboard';
import HistorySecDashboard from './dashboardComponents/HistorySecDashboard';
import ReferNowSection from "./dashboardComponents/ReferNowSection";
import { useMixpanel } from '../context/MixpanelContext'; 
import mixpanel from "mixpanel-browser";
import { logDOM } from "@testing-library/react";

const Dashboard = () => {
  const { mixpanelUserId } = useMixpanel();

  useEffect(() => {
    if (mixpanelUserId) {
      mixpanel.identify(mixpanelUserId);

      mixpanel.track("Viewed_PERP_Dashboard", {}); 
      
    }
  }, [mixpanelUserId]);
  function getTokenFromURL() {
    const params = new URLSearchParams(window.location.search);
    return params.get("token"); 
  }
  const token = getTokenFromURL();
  if (token) {
    localStorage.setItem('authToken', token);
  }

  return (
    <>
      <div className='container'>
        <div id='background'>
          <TopSecDashboard />
          <div className='mainbackground'>
            <div style={{ marginLeft: "20px", marginRight: "20px" }}>
              <MidSecDashboard />
              <HowItWorksSecDashboard />
            </div>
            <HistorySecDashboard />
            <ReferNowSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
