import React, { useEffect, useState } from 'react';
import '../../styles/main.css';
import handholdingmoney from "../../assets/cashgreen.png";
import { fetchUserReferredList } from "../../api/action";
import moneycheck from "../../assets/moneycheck.png";
import perpuser from "../../assets/perpuser.png";
import ToastMsg from '../../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../common/Loader';

const MidSecDashboard = () => {
  const [referredList, setReferredList] = useState([]);
  const [error, setError] = useState(null);
  const [loader, setloader] = useState(false);
  

 

  const handleReferredList = () => {
    setloader(true)
    fetchUserReferredList()
      .then((data) => {
        setloader(false)
        // ToastMsg('Referred list fetched successfully!', 'success');
        setReferredList(data);
      })
      .catch((err) => {
        const errorMsg = err?.message || 'Error fetching referred list';
        ToastMsg(errorMsg, 'error');
        setError(errorMsg);
        setloader(false)
      });
  };
  

  useEffect(() => {
    handleReferredList();
  }, []);

  return (
    <>
    {/* {error && <p className="error">Error: {error}</p>} */}
    <div className="stats-section">
    {loader ? <Loader /> : null}
    
      <div className="stat commonOutline">
        <span>People Referred</span>
        <div className="flex-row-center">
          <img width={36} height={36} src={perpuser} alt="People Referred" />
          <p className="f-weight700 paymecolour">{referredList?.people_reffered}</p>
        </div>
      </div>

      <div className="stat commonOutline marginleft-right-5">
        <span>Loan Approved</span>
        <div className="flex-row-center">
          <img width={36} height={36} src={moneycheck} alt="Loan Approved" />
          <p className="f-weight700"> {referredList?.limit_assigned_count}</p>
        </div>
      </div>

      <div className="stat commonOutline">
        <span>Loan Disbursed</span>
        <div className="flex-row-center">
          <img width={36} height={36} src={handholdingmoney} alt="Loan Disbursed" />
          <p className="f-weight700 paymegreencolour">{referredList?.loan_disbursed_count}</p>
        </div>
      </div>
      
      
    </div>
    </>
   
  );
};

export default MidSecDashboard;
