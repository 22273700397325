import React, { createContext, useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser"; 


const MixpanelContext = createContext(null);


export const MixpanelProvider = ({ children }) => {
  const [mixpanelUserId, setMixpanelUserId] = useState(null);

  function getUserIDFromURL() {
    const User_id =new URLSearchParams(window.location.search).get("user_id")
    return User_id; 
  }
  useEffect(() => {
    const userID = getUserIDFromURL(); 
    if (userID) {
      localStorage.setItem('userIdMixpanel', userID);
      setMixpanelUserId(userID); 
      mixpanel.identify(userID);
    } else {
      const storedUserId = localStorage.getItem("userIdMixpanel");
      if (storedUserId) {
        setMixpanelUserId(storedUserId); 
        mixpanel.identify(storedUserId);
      }
    }
  }, []);

  return (
    <MixpanelContext.Provider value={{ mixpanelUserId }}>
      {children}
    </MixpanelContext.Provider>
  );
};


export const useMixpanel = () => useContext(MixpanelContext);
