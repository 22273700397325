// src/App.js
import React from 'react';
import Router from './routes/Router';
import './styles/main.css';
import mixpanel from "mixpanel-browser";
import { MixpanelProvider } from './context/MixpanelContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const MIXPANEL_ID = `${process.env.REACT_APP_MIXPANEL_TOKEN}`;

const App = () => {
  
  mixpanel.init(MIXPANEL_ID);

  return (
    <MixpanelProvider> 
      <Router />
      <ToastContainer />
    </MixpanelProvider>
  );
};

export default App;
