import React, { useState, useEffect } from 'react';
import '../styles/main.css';
import { fetchUserRewardHistory } from "../api/action";
import paymeCoin from '../assets/PayMeCoin.png';
import CalenderImg from '../assets/calender.png';
import User from '../assets/user.png'
import TimerImg from '../assets/time.png';
import CopyImg from "../assets/copyImg.png";
import Backbtn from '../assets/backkbtn.png'
import ReferNowSectionAll from './dashboardComponents/ReferNowSectionAll';
import ToastMsg from '../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../common/Loader';
import { useFetcher, useNavigate } from 'react-router-dom';
import moment from 'moment';



const HistorySecDashboard = () => {
  const [rewardHistory, setRewardHistory] = useState([]);
  const [error, setError] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); 
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [loader, setloader] = useState(false);
  const [txnTypeglobal, setTxnTypeglobal] = useState('');
  const navigate = useNavigate();
  
  

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  
    const filtered = rewardHistory.filter((transaction) => {
      const txnType = getTransactionDisplayName(transaction.txn_type, transaction.redeemed_status).toLowerCase();
      const txnValue = transaction.txn_value.toString();
      const txnId = transaction.transactionn_id.toLowerCase();
      const userName = transaction.name ? transaction.name.toLowerCase() : '';
  
      return txnType.includes(query) || txnValue.includes(query) || txnId.includes(query) || userName.includes(query); 
    });
  
    setFilteredHistory(filtered);
  };
  

  const token = localStorage.getItem('authToken');

  useEffect(() => {
    setloader(true)
    if (token) {
      fetchUserRewardHistory()
  .then((data) => {
    setloader(false)
    console.log('Fetched Data history:', data.transactions);
    setRewardHistory(data.transactions);
    setFilteredHistory(data.transactions);
  })
  .catch((err) => {
    setloader(false)
    const errorMsg = err.message || 'Error fetching reward history';
    ToastMsg(errorMsg, 'error');
    setError(errorMsg);
  });

    }
  }, [token]); 

  const segregateHistoryByDate = (transactions) => {
    const today = new Date();
    const todayUTC = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const yesterdayUTC = new Date(Date.UTC(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate()));

    const todayTransactions = [];
    const yesterdayTransactions = [];
    const earlierTransactions = [];

    transactions?.forEach((transaction) => {
      const txnDate = new Date(transaction.txn_date);

      if (txnDate.toDateString() === todayUTC.toDateString()) {
        todayTransactions.push(transaction);
      } else if (txnDate.toDateString() === yesterdayUTC.toDateString()) {
        yesterdayTransactions.push(transaction);
      } else {
        earlierTransactions.push(transaction);
      }
    });

    return { todayTransactions, yesterdayTransactions, earlierTransactions };
  };

  const { todayTransactions, yesterdayTransactions, earlierTransactions } = segregateHistoryByDate(filteredHistory); 

  const formatDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY');
  };
  
  const formatTime = (timeString) => {
    return moment(timeString, 'HH:mm:ss').format('hh:mm A');
  };
  

  const getTransactionDisplayName = (txnType, redeemedStatus) => {
    switch (txnType) {
        case "self_limit_assigned":
            return redeemedStatus === "IN_PROGRESS" ? "Loan Approved (Self) (In Progress)" : "Loan Approved (Self)";
        case "self_loan_disbursed":
            return "Loan Disbursed (Self)";
        case "referred_limit_assigned":
            return redeemedStatus === "IN_PROGRESS" ? "Loan Approved (In Progress)" : "Loan Approved";
        case "referred_loan_disbursed":
            return "Loan Disbursed";
        case "redeemed":
            return redeemedStatus === "IN_PROGRESS" ? "Redeemed (In Progress)" : "Redeemed";
        case "registered":
            return "Registered";
        case "FAILURE":  
            return "Transaction Failed";
        default:
            return txnType;
    }
};

  const hadledasboardredirect = () =>{
    navigate('/');
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        
        setIsCopied(true);
        localStorage.setItem('copiedtoclipboard', isCopied); 
        
        setTimeout(() => {
          setIsCopied(false);
          localStorage.removeItem('copiedtoclipboard'); 
        }, 2000);
      })
      .catch((err) => {
        ToastMsg('Failed to copy text to clipboard!', 'error');
        console.error('Failed to copy text: ', err);
        setloader(false)

      });
  };
  

  return (
    <div className="history-sections">
      {loader ? <Loader /> : null}
      <div className="history-header-trans all-transction-header">
       <div className='flex-row-center-trans'>
       <img onClick={hadledasboardredirect} src={Backbtn}  width={42} height={42}/>
       <span className='font70030 ml-35'>All Transactions</span>
       </div>
        
       <div className="search-container">
        <input
            type="text"
            placeholder="Search by Name"
            className="search-history"
            value={searchQuery}
            onChange={handleSearch}
        />
        <span className="search-icon">&#128269;</span> 
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}

      {todayTransactions.length > 0 && (
        <div className="history-day">
          <h3 className='todayyest pl-40 '>Today</h3>
          {todayTransactions.map((item, index) => {
            const transactionId = String(item.transactionn_id);
            const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

            return (
              <React.Fragment key={index}>
              <div className="history-item mb-12 ">
                <div className="history-item-content ">
                  <div className="history-item-main">
                    <p className="transaction-type history-trans-heading">
                      {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                        <>
                          Redeemed{' '}
                          <span className='paymecolournew inprog inprogress' style={{ color: 'rgba(255, 162, 0, 1)', marginLeft: "3px" }}>
                            (In Progress)
                          </span>
                        </>
                      ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                        <>
                          Redeemed{' '}
                          <span className='paymecolournew inprog failed' style={{ color: 'red', marginLeft: "3px" }}>
                            (Failed)
                          </span>
                        </>
                      ) : (
                        getTransactionDisplayName(item.txn_type, item.redeemed_status)
                      )}
                    </p>
            
                    {item.txn_type !== "registered" && (
                      <p
                        className={
                          item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS"
                            ? 'flex'
                            : item.redeemed_status === "FAILED"
                            ? 'negative-amount flex'
                            : item.txn_value > 0 
                            ? 'positive-amount flex'
                            : 'negative-amount flex'
                        }
                      >
                        {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                          <div className='inprogress'>
                            <img
                              className='paymecoinfixhistory1'
                              src={paymeCoin}
                              alt="Icon"
                              style={{ width: '16px', height: '16px', margin: '0 4px' }}
                            />
                            <span>{Math.abs(item.txn_value)}</span>
                          </div>
                        ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                          <div style={{ color: "red" }}>
                            <img
                              className='paymecoinfixhistory1'
                              src={paymeCoin}
                              alt="Icon"
                              style={{ width: '16px', height: '16px', margin: '0 4px' }}
                            />
                            <span>{Math.abs(item.txn_value)} (Failed)</span>
                          </div>
                        ) : item.txn_type === "redeemed" ? (
                          <div style={{ color: "red" }}>
                            -<img
                              className='paymecoinfixhistory1'
                              src={paymeCoin}
                              alt="Icon"
                              style={{ width: '16px', height: '16px', margin: '0 4px' }}
                            />
                            <span>{Math.abs(item.txn_value)}</span>
                          </div>
                        ) : (
                          <>
                            {item.txn_value > 0 ? '+' : '-'}
                            <img
                              className=''
                              src={paymeCoin}
                              alt="Icon"
                              style={{ width: '16px', height: '16px', margin: '0 4px' }}
                            />
                            {Math.abs(item.txn_value)}
                          </>
                        )}
                      </p>
                    )}
                  </div>
            
                  <div className="history-item-details">
                    <p className="transaction-details">
                      {item?.name && (
                        <span className="date-icon">
                          <img className='hystoryminiicon' src={User} width={14} alt="User Icon" />
                          {(() => {
                            const parts = item.name.split(" ");
                            const firstWord = parts[0];
                            const secondWordFirstLetter = parts[1]?.charAt(0);
            
                            const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                            const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
            
                            return (
                              <span className='ml-4'>
                                {capitalizedFirstWord}
                                {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                              </span>
                            );
                          })()}
                        </span>
                      )}
            
                      <span className="date-icon">
                        <img className='hystoryminiicon' src={CalenderImg} width={14} alt="Calendar Icon" />
                      </span>
                      {formatDate(item.txn_date)}
            
                      <span className="time-icon">
                        <img className='hystoryminiicon' src={TimerImg} width={14} alt="Timer Icon" />
                      </span>
                      {formatTime(item.txn_time.split('.')[0])}
            
                      {!item?.name && (
                        <>
                          <span style={{ marginLeft: "5px", marginRight: '5px' }}>|</span>
                          <span className='mr-10'>
                            Txn. ID: xx{lastFourDigits}
                            <img
                              className='ml-5'
                              src={CopyImg}
                              width={14}
                              height={14}
                              alt="Copy"
                              onClick={() => copyToClipboard(transactionId)}
                            />
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
            

            

            );
          })}
        </div>
      )}

      {yesterdayTransactions.length > 0 && (
        <div className="history-day">
          <h3 className='todayyest pl-40'>Yesterday</h3>
          {yesterdayTransactions.map((item, index) => {
            const transactionId = String(item.transactionn_id);
            const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

            return (
              <div className="history-item" key={index}>
              <div className="history-item-content ">
                <div className="history-item-main">
                  <p className="transaction-type history-trans-heading">
                    {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                      <>
                        Redeemed <span style={{marginLeft:"3px"}} className='paymecolournew inprog'> (In Progress)</span>
                      </>
                    ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                      <>
                        Redeemed <span style={{marginLeft:"3px", color: "red"}} className='paymecolournew failed'> (Failed)</span>
                      </>
                    ) : (
                      getTransactionDisplayName(item.txn_type, item.redeemed_status)
                    )}
                  </p>
            
                  {item.txn_type !== "registered" && (
                    <p className={item.txn_value > 0 ? 'positive-amount flex' : 'negative-amount flex'}>
                      {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                        <div className='inprogress'>
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : (
                        <>
                          {item.txn_value > 0 ? '+' : '-'}
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          {Math.abs(item.txn_value)}
                        </>
                      )}
                    </p>
                  )}
                </div>
            
                <div className="history-item-details">
                  <p className="transaction-details earliat-transtions">
                    {item?.name && (
                      <span className="date-icon">
                        <img className='hystoryminiicon' src={User} width={14} alt="User Icon" />
                        {(() => {
                          const parts = item.name.split(" ");
                          const firstWord = parts[0];
                          const secondWordFirstLetter = parts[1]?.charAt(0);
                          
                          const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                          const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
            
                          return (
                            <span className='ml-4'>
                              {capitalizedFirstWord}
                              {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                            </span>
                          );
                        })()}
                      </span>
                    )}
            
                    <span className="date-icon">
                      <img className='hystoryminiicon' src={CalenderImg} width={14} alt="Calendar Icon" />
                    </span>
                    {formatDate(item.txn_date)}
                    
                    <span className="time-icon">
                      <img className='hystoryminiicon' src={TimerImg} width={14} alt="Timer Icon" />
                    </span>
                    {formatTime(item.txn_time.split('.')[0])}
            
                    {!item?.name && (
                      <>
                        <span style={{ marginLeft: "5px", marginRight: '5px' }}>|</span>
                        <span className='mr-10'>
                          Txn. ID: xx{lastFourDigits}
                          <img 
                            className='ml-5' 
                            src={CopyImg} 
                            width={14}  
                            height={14}  
                            alt="Copy" 
                            onClick={() => copyToClipboard(transactionId)}
                          />
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>

            );
          })}
        </div>
      )}

      {earlierTransactions.length > 0 && (
        <div className="history-day">
          <h3 className='todayyest pl-40'>Earlier</h3>
          {earlierTransactions.map((item, index) => {
            const transactionId = String(item.transactionn_id);
            const lastFourDigits = transactionId.length >= 4 ? transactionId.slice(-4).toUpperCase() : 'N/A';

            return (
              <div className="history-item" key={index}>
              <div className="history-item-content ">
                <div className="history-item-main">
                  <p className="transaction-type history-trans-heading">
                    {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                      <>
                        Redeemed <span style={{ marginLeft: "3px" }} className="paymecolournew inprog">(In Progress)</span>
                      </>
                    ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                      <>
                        Redeemed <span style={{ marginLeft: "3px", color: "red" }} className="paymecolournew failed">(Failed)</span>
                      </>
                    ) : (
                      getTransactionDisplayName(item.txn_type, item.redeemed_status)
                    )}
                  </p>
            
                  {item.txn_type !== "registered" && (
                    <p className={item.txn_value > 0 ? 'positive-amount flex' : 'negative-amount flex'}>
                      {item.txn_type === "redeemed" && item.redeemed_status === "IN_PROGRESS" ? (
                        <div className="inprogress">
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" && item.redeemed_status === "FAILED" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : item.txn_type === "redeemed" ? (
                        <div style={{ color: "red" }}>
                          -<img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          <span>{Math.abs(item.txn_value)}</span>
                        </div>
                      ) : (
                        <>
                          {item.txn_value > 0 ? '+' : '-'}
                          <img
                            src={paymeCoin}
                            alt="Icon"
                            style={{ width: '16px', height: '16px', margin: '6px 4px' }}
                          />
                          {Math.abs(item.txn_value)}
                        </>
                      )}
                    </p>
                  )}
                </div>
            
                <div className="history-item-details">
                  <p className="transaction-details earliat-transtions">
                    {item?.name && (
                      <span className="date-icon">
                        <img className="hystoryminiicon" src={User} width={14} alt="User Icon" />
                        {(() => {
                          const parts = item.name.split(" ");
                          const firstWord = parts[0];
                          const secondWordFirstLetter = parts[1]?.charAt(0);
                          
                          const capitalizedFirstWord = firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
                          const capitalizedSecondWordFirstLetter = secondWordFirstLetter ? secondWordFirstLetter.toUpperCase() : '';
                          
                          return (
                            <span className='ml-4'>
                              {capitalizedFirstWord}
                              {capitalizedSecondWordFirstLetter ? ` ${capitalizedSecondWordFirstLetter}` : ''}
                            </span>
                          );
                        })()}
                      </span>
                    )}
            
                    <span className="date-icon">
                      <img className="hystoryminiicon" src={CalenderImg} width={14} alt="Calendar Icon" />
                    </span>
                    {formatDate(item.txn_date)}
            
                    <span className="time-icon">
                      <img className="hystoryminiicon" src={TimerImg} width={14} alt="Timer Icon" />
                    </span>
                    {formatTime(item.txn_time.split('.')[0])}
            
                    {!item?.name && (
                      <>
                        <span style={{ marginLeft: "5px", marginRight: '5px' }}>|</span>
                        <span className="mr-10">
                          Txn. ID: xx{lastFourDigits}
                          <img
                            className="ml-5"
                            src={CopyImg}
                            width={14}
                            height={14}
                            alt="Copy"
                            onClick={() => copyToClipboard(transactionId)}
                          />
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            
            

            
            
            

            );
          })}
        </div>
      )}
      <ReferNowSectionAll />

      {filteredHistory.length === 0 && <p className='notransctionfound'>No transactions found.</p>}
    </div>
  );
};

export default HistorySecDashboard;

