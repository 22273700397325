import React, { useState, useEffect } from "react";
import Crossbtn from "../assets/crossbtn.png";
import { fetchUserReferLink } from "../api/action";
import mixpanel from "mixpanel-browser";
import '../styles/main.css';

const FaqPopup = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [referLinkData, setReferLinkData] = useState(null);
  const [error, setError] = useState(false);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleReferLink = () => {
    fetchUserReferLink()
      .then((data) => {
        setReferLinkData(data);
        console.log(referLinkData?.referral_link, "data");
      })
      .catch((err) => {
        setError(err?.message);
        console.log("Error fetching user refer link:", err);
      });
  };

  useEffect(() => {
    handleReferLink();
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    if (mixpanelUserId) {
      mixpanel.identify(mixpanelUserId);
      mixpanel.track("Opened_FAQ_Popup", {});
    }
  }, []);

  function openNativeShare(referLinkData) {
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    mixpanel.track("Interacted_PERP_FAQs", {
      "Clicked on": "Refer",
    });
    const referralLink = referLinkData;
    const shareData = {
      title: 'Check out PaymeIndia!',
      text: `Hey, I’m using PayMe for instant, hassle-free personal loans. Use this link to download the app`,
      url: referralLink
    };

    if (navigator.share) {
      navigator.share(shareData).then(() => {
        console.log('Thanks for sharing!');
      }).catch((error) => {
        console.error('Error sharing:', error);
      });
    } else if (window.Android) {
      window.Android.shareText(shareData.title, shareData.text, shareData.url);
      return;
    } else {
      alert('Your browser does not support the native share feature.');
    }
  }

  const handlePopupToggle = () => {
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    mixpanel.track("Interacted_PERP_FAQs", {
      "Clicked on": "Back",
    });
    setShowPopup(false);
    window.location.reload();
    if (!showPopup) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  return (
    showPopup && (
      <div className="popup">
        <div className="popup-header ppoheader">
          <span className="mr-50"> </span>
          <span className="headerheadingpopup">FAQs</span>
          <button className="close-btn" onClick={handlePopupToggle}>
            <img src={Crossbtn} width={32} height={32} />
          </button>
        </div>
        <div className="popup-content popupouterbox">
          <div className="faq-item popupBox ">
            <h3>What is the PayMe Enhanced Referral Program (PERP)?</h3>
            <div className="gradient-bg2" />
            <p>
              PERP is a loyalty program where you can earn rewards by referring
              others to PayMe. You and your referral can earn PayMe Coins when
              certain conditions are met.
            </p>
          </div>
          <div className="faq-item popupBox">
            <h3>How do I earn PayMe Coins?</h3>
            <div className="gradient-bg2" />
            <p>
              You earn PayMe Coins when you refer someone to PayMe and they
              successfully get a credit limit or their loan is disbursed. Both
              you and your referral will earn{" "}
              <span className="highlight">400 coins</span> when their credit
              limit is approved, and <span className="highlight">600 coins</span>{" "}
              when their loan is disbursed.
            </p>
          </div>
          <div className="faq-item popupBox">
            <h3>How can I use my PayMe Coins?</h3>
            <div className="gradient-bg2" />
            <p>
              PayMe Coins can be redeemed for cash which will be directly
              credited to your account.
            </p>
          </div>
          <div className="faq-item popupBox">
            <h3>How much is one PayMe Coin worth?</h3>
            <div className="gradient-bg2" />
            <p>10 PayMe Coin is worth <span className="highlight">1 Rupee.</span></p>
          </div>
          <div className="faq-item popupBox ">
            <h3>How long does it take for PayMe Coins to be credited?</h3>
            <div className="gradient-bg2" />
            <p>
              PayMe Coins will be credited to your account within <span className="highlight"> 24 hours</span> after your referral’s credit limit is approved or their loan is disbursed.
            </p>
          </div>
          <div className="faq-item popupBox ">
            <h3>Do I earn PayMe Coins if my credit limit is approved or my loan is disbursed?</h3>
            <div className="gradient-bg2" />
            <p>
              No, you do not earn PayMe Coins for your own credit limit approval or loan disbursement. Coins are only earned through the referral process.
            </p>
          </div>
          <div className="faq-item popupBox mb-100">
            <h3>How can I track my PayMe Coins?</h3>
            <div className="gradient-bg2" />
            <p>
              You can track your PayMe Coins in your user profile on the PayMe app or website.
            </p>
          </div>
        </div>
        <div className='textaligncenter positionfixedbottom0 button-wrapper'>
          <button onClick={() => openNativeShare(referLinkData?.referral_link)} className="refer-btn-popup ">Refer Now</button>
        </div>
      </div>
    )
  );
};

export default FaqPopup;
