import axios from "axios";



const API_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/perp/api/v1`
const CLAIM_REWARD_API_ENDPOINT = `${API_ENDPOINT}/user-claim-rewards`;
const USER_REFER_LINK_API_ENDPOINT = `${API_ENDPOINT}/user-refer-link`;


const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

export const claimReward = () => {
  const token = getAuthToken();
  let config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(CLAIM_REWARD_API_ENDPOINT, config)
      .then((res) => {
        resolve(res.data); 
      })
      .catch((err) => {
        reject(err);  
      });
  });
};

export const fetchUserRewardDetails = () => {
  const token = getAuthToken();
  let url = `${API_ENDPOINT}/user-reward-details`;

  let config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchUserRewardHistory = () => {
  const token = getAuthToken();
    let url = `${API_ENDPOINT}/user-reward-history`; 
  
    let config = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    };
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => {
          resolve(res.data); 
        })
        .catch((err) => {
          reject(err); 
        });
    });
  };

  export const fetchUserClaimRewardsStatus = async () => {
    const token = getAuthToken();
    const url = `${API_ENDPOINT}/user-claim-rewards-status`;
    
  
    const config = {
      headers: {
        Authorization: token,
        "Content-Type": "application/json",  
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (error) {
      console.error('Error fetching user claim rewards status:', error);
      throw error;
    }
  };

  export const fetchUserReferredList = () => {
    const token = getAuthToken();
    let url = `${API_ENDPOINT}/user-referred-list`;

    
     
    
  
    let config = {
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    };
  
    return new Promise((resolve, reject) => {
      axios
        .get(url, config)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };


export const fetchUserReferLink = () => {
  const token = getAuthToken(); 

  let config = {
    headers: {
      Authorization: token, 
      "Content-Type": "application/json",
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .get(USER_REFER_LINK_API_ENDPOINT, config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};





