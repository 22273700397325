import React, { useEffect, useState, useRef } from 'react';
import '../../styles/main.css';
import paymeCoin from '../../assets/PayMeCoin.png';
import { fetchUserRewardDetails, claimReward, fetchUserClaimRewardsStatus } from "../../api/action";
import Backbtn from '../../assets/backbtn.png'
import QuestinMark from "../../assets/questionmark.png"
import FaqPopup from '../FaqPopup';
import BankImgforpOpup from '../../assets/bank.png'
import CrossImg from "../../assets/crossblack.png"
import Greencheck from "../../assets/greencheck.png"
import popupback  from "../../assets/loanforwardbtn.png"
import PopupErrorimg from "../../assets/popupError.png"
import ToastMsg from '../../common/ToastMsg';
import "react-toastify/dist/ReactToastify.css";
import { Loader } from '../../common/Loader';
import GreenCheck from '../../assets/greenrightcheck.gif'
import mixpanel from 'mixpanel-browser';



const TopSecDashboard = () => {
  const [rewardDetails, setRewardDetails] = useState(null); 
  const [error, setError] = useState(null); 
  const [isfaqpop, setIsfaqpop] = useState(false);
const [isCopied, setIsCopied] = useState(false);
const [loader, setLoader] = useState(false);
const [showModal, setShowModal] = useState(false);
const [transactionId, setTransactionId] = useState(null);
const [bankdetailsClose , setBankDetailClose] = useState(false);
const [rewardDetailsLoaded, setRewardDetailsLoaded] = useState(localStorage.getItem('rewardDetailsLoaded') === 'true');




  const [isOpen, setIsOpen] = useState(false);



  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      handleClose(); 
    }
  };
  const handleFetchUserRewardStatus = () =>{
    fetchUserClaimRewardsStatus()
  .then((data) => {
    console.log('User claim rewards status:', data.in_progress);
    if (data?.in_progress) {
      const inProgressMessage = true;
      localStorage.setItem('inprogressError', inProgressMessage);

      setTimeout(() => {
        setIsCopied(false);
        localStorage.removeItem('inprogressError'); 
      }, 2000);
    } else {
      handleOpen(); 
    }
  })
  .catch((error) => {
    console.error('Error:', error);
  });

  }




  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  



  const handleOpen = () => {
    const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
    mixpanel.identify(mixpanelUserId)
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll'); 
    }
  };

  const handleClose = () => {
    if (!bankdetailsClose){
      mixpanel.track("Interacted_PERP_Bank_Details_Addition", {
        "Clicked on": "Exit",
      });
    }
    else{

    mixpanel.track("Interacted_PERP_Claim_Now_Popup", {
      "Clicked on": "Exit",
    });
  }
    
  

    setIsOpen(false);
    document.body.classList.remove('no-scroll'); 
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
  
  

  const handlePopupToggle = () => {
    const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
    mixpanel.identify(mixpanelUserId)
    mixpanel.track("Interacted_PERP_Dashboard", {
      "Clicked on": "Know More",
    });
    setIsfaqpop(!isfaqpop);
    
    if (!isfaqpop) {
      document.body.classList.add('no-scroll'); 
    } else {
      document.body.classList.remove('no-scroll'); 
    }
  };

  const closeModal = () => {
    setShowModal(false);
    window.location.reload(); 
  };
  

  const handleRewardDetails = () => {
    setLoader(true);
    fetchUserRewardDetails()
      .then((data) => {
        setLoader(false);
        setRewardDetails(data);
        localStorage.setItem('rewardDetails', JSON.stringify(data));
  
        localStorage.setItem('rewardDetailsLoaded', 'true');
        window.dispatchEvent(new Event('storageChanged'));
      })
      .catch((err) => {
        setLoader(false);
        setError(err?.message);
        ToastMsg(error, "error");
      });
  };
  
  
  


  
  
 

  const handleWebRedirect = () => {
    setBankDetailClose(true);
    const mixpanelUserId = localStorage.getItem("userIdMixpanel");
    mixpanel.identify(mixpanelUserId);
    mixpanel.track("Interacted_PERP_Bank_Details_Addition", {
      "Clicked on": "Add Details",
    });
    window.open("https://apiv2.paymeindia.in/close", "_self"); 
  };
  
  

const handleClaimReward = () => {
  
  const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
  mixpanel.identify(mixpanelUserId)
  mixpanel.track("Interacted_PERP_Claim_Now_Popup", {
    "Clicked on": "Submit",
  });

 


 
  setLoader(true)
  claimReward()
  
    .then((data) => {
      {(data?.msg =="payment initiated and in_progress")&&
        setTransactionId(data?.claim_id)
        if(data?.in_progress){
          ToastMsg(data?.msg,"success")
        }else{
        setShowModal(true)
        setIsOpen(false)
        document.body.classList.remove('no-scroll');
      }
      }
      setLoader(false)
      {(data?.msg !=="payment initiated and in_progress")&&
      ToastMsg(data?.msg || 'Reward claimed successfully!', 'success');}
      console.log('Reward claimed successfully:', data);
    })
    .catch((err) => {
      setIsCopied(true);
      const errorMsg = err?.response?.data?.msg || 'Error claiming reward';
      setError(errorMsg);
      localStorage.setItem('claim', isCopied);
      localStorage.setItem('claimerror', errorMsg);

      ToastMsg(errorMsg, 'error');

      setTimeout(() => {
        setIsCopied(false);
        localStorage.removeItem('copiedtoclipboard');
        localStorage.removeItem('claimerror');
        setError(null);
      }, 6000);
      console.error('Error claiming reward:', errorMsg);
    });
};


const handleClaimClick = () => {
  const mixpanelUserId =  localStorage.getItem("userIdMixpanel")
  mixpanel.identify(mixpanelUserId)
  mixpanel.track("Interacted_PERP_Dashboard", {
    "Clicked on": "Claim now",
  });
  
  if (rewardDetails?.coins < 550) {
    const errorMessage = 'Minimum 550 Coins required to claim.';
    
    setError(errorMessage);
    localStorage.setItem('error', errorMessage);
    
    setTimeout(() => {
      localStorage.removeItem('error');
      setError('');  
    }, 6000); 
    
    return; 
  }


  handleFetchUserRewardStatus()


};
useEffect(() => {
  if (!rewardDetails) {
    handleRewardDetails();
  }
}, [rewardDetails]);

 
 console.log(Backbtn , "rewarddetails");
 
  return (
    <>
     {isfaqpop && <div className="overlay" onClick={handlePopupToggle}></div>} 
     {isfaqpop && <FaqPopup />} 
    
    <div className="top-section-dashboard">
    {loader ? <Loader /> : null}

      <div className='header'>
              <span></span>
              <h1 className='font700'>Refer & Earn</h1>
              <img className='mb-9' src={QuestinMark} onClick={handlePopupToggle}  alt='quemark' width={20}   height={20} />
      </div>

      <div className="balance-info">
        <div className="balance">
          <div  className="coins flex-row align-centre mb-25">
          <div className="mandatory-step ">
          <img className='mr-8 mb-1' src={paymeCoin} width={38} height={38} alt='paymecoin' />
        
          
      </div>
            
            <div>
              <p>{rewardDetails?.coins?.toLocaleString('en-IN') || 0}</p>
              <span>Worth ₹{rewardDetails?.coins_values || 0}</span>
            </div>
          </div>
          <div className="flex-col">
            <button className="claim-btn" onClick={handleClaimClick}>Claim</button>
            <span className="flex-row-center rupeecoin">
              <img src={paymeCoin} width={15} height={15} alt='paymecoin' /> 10 = ₹1
            </span>
          </div>
        </div>
        <div className="width100">
          <p className="claimed-info">₹{rewardDetails?.claimed?.toLocaleString('en-IN')} Claimed until now</p>
        </div>
      </div>


      {showModal && (
        <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-checkmark"><img src={GreenCheck} width={120} height={121.24} alt='succesgif'/></div>
          <h2 className='withdraw-popup'>Withdraw Successful</h2>
          <p> Sit back and relax! <br/>Your funds will be credited to your account within the next 48 hours.</p>
          <p className='transcrtionid'>
            <strong >Transaction ID:</strong> {transactionId}
          </p>
          <button className="modal-btn" onClick={closeModal}>
            Okay
          </button>
        </div>
      </div>
      )}


      <div className="withdraw-popup-container">
      {/* <button onClick={handleOpen} className="withdraw-open-button">Open Popup</button> */}

    

      {isOpen && (
      <div className="withdraw-popup-overlay">
        <div className="withdraw-popup-content" ref={popupRef}>
          <img className='floatright' onClick={handleClose} width={22} src={CrossImg} />
          <div className="withdraw-popup-header">
            {rewardDetails?.bank_name ? 
              <img src={BankImgforpOpup} width={140} height={154} alt="Withdraw Illustration" className="withdraw-popup-image" /> :
              <img src={PopupErrorimg} width={172} height={134} alt="Withdraw Illustration" className="withdraw-popup-image ml-25" />
            }
          </div>
          <div className="withdraw-popup-body referNowSection">
            {rewardDetails?.bank_name ? 
              <h2 className="withdraw-popup-title">Withdraw Cash Worth <span className="withdraw-popup-amount">₹{rewardDetails?.coins/10}</span></h2> :
              <h2 className="withdraw-popup-title">Bank account details not added!</h2>
            }
            {rewardDetails?.bank_name ? 
              <p className="withdraw-popup-text">Your amount will be transferred to the following bank account:</p> :
              <p className="withdraw-popup-text"> To add bank details:</p>
            }

            <div className="withdraw-popup-account">
              <div className="withdraw-popup-select">
                {rewardDetails?.bank_name ? 
                  <span className='banknamemajor'>{rewardDetails?.bank_name} • <span className='bankname'>XXXX{rewardDetails?.account_number.slice(-4)} </span> <img src={Greencheck} width={14} /></span> :
                  <span className='errorpopup'>Go to Apply loan <img className='mrml10' src={popupback} width={28} /> Fill Bank Details</span>
                }
              </div>
            </div>
          </div>
          {rewardDetails?.bank_name ? 
            <button onClick={handleClaimReward} className="refer-btn-popup">Submit</button> :
            <button onClick={handleWebRedirect} className="refer-btn-popup">Add Details</button>
          }
        </div>
        {isCopied && 
          <div className="error-message-copied">
            <p className="copied-notification copiedtext">{error}</p>
          </div>
        }
      </div>
    )}


    </div>
    </div>

    </>
  );
};

export default TopSecDashboard;



